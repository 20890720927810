html {
    background: #B9B4A8;

    font-family: "Baskervville", serif;
    font-size: 1.1rem;
    line-height: 1.55;
    color: #F1EEEE;
}

.paragraph {
    /* font-family: "Lora", system-ui; */
    font-family: "Lato", Arial, Helvetica, sans-serif;
    font-weight: 100;
}

a {
    color: #d5ddd3;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

h3 {
    text-transform: uppercase;
    margin-top: 0;
}

.hero_item {
    color: #F1EEEE;
    font-family: "Baskervville", serif;
}


@media screen and (min-width: 1401px) {
    .page_container {
        width: 1120px;
        margin: 2rem auto;
    }

    .item_image {
        min-height: 200px;
    }

    .hero_item {
        min-height: 90vh;
    }

    div.item_container.hero_item>h1 {
        font-size: 4rem;
    }

    div.item_container.hero_item>h2 {
        font-size: 2rem;
    }

}

@media screen and (min-width: 600px) and (max-width: 1400px) {
    .page_container {
        width: 80vw;
        margin: 2rem auto;
        /* border: 4px dotted green; */
    }

    div.item_container.hero_item>h1 {
        font-size: 3rem;
        margin-bottom: 0.5rem;
    }

    div.item_container.hero_item>h2 {
        margin-top: 0;
        font-size: 1.5rem;
    }

    .item_image {
        min-height: 200px;
    }

    .hero_item {
        min-height: 65vh;
    }

}

@media screen and (max-width: 599px) {
    .page_container {
        width: 100%;
        margin: 0 auto;
        /* border: 4px dotted blue; */
    }
}

.grid_container {
    display: grid;
    gap: 1rem;
}

@media screen and (min-width: 500px) {
    .grid_container {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .hero_item {
        grid-column: span 3 / auto;
        grid-row: span 30 / auto;
    }

    .item_wide {
        grid-column: span 2 / auto;
    }
}

@media screen and (max-width: 499px) {
    .grid_container {
        grid-template-columns: 1fr;
    }

    .item_wide {
        grid-column: span 1 / auto;
    }

    .hero_item {
        grid-column: 100%;
        min-height: 60vh;
    }

    .item_image {
        min-height: 300px;
    }
}

.title {
    /* font-family: "Lora", system-ui; */
    /* font-style: italic; */
    font-optical-sizing: auto;
    font-weight: 600;
    line-height: 120%;
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.3));
}

.item_container {
    background-color: rgb(245, 245, 245);
    border-radius: 1rem;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3));
}

.hero_item {
    background-image: url("./assets/unsplash_oslo.jpg");
    background-size: cover;
    background-position: top;
    text-align: center;
}


.item_tall {
    grid-row: span 2 / auto;
}

.item_taller {
    grid-row: span 2 / auto;
}

.item_image {
    background-size: cover;
    background-position: center;
}

.some_image {
    background-size: cover;
    background-position: center;
}

.text_item {
    padding: 1rem;
}

.text_bold {
    font-weight: 600;
}

.oslo1_img {
    background-image: url("./assets/oslo1.png");
    height: 10rem;
}

.oslo2_img {
    background-image: url("./assets/oslo2.png");
}

.oslo3_img {
    background-image: url("./assets/oslo3.png");
}

.thomas_img {
    background-image: url("./assets/thomas.png");
    filter: sepia(0.5);
}

.kristian_tv_img {
    background-image: url("./assets/kristian_tv.png");
}

.prisma_img {
    background-image: url("./assets/prisma_darker.png");
}

.munch_img {
    background-image: url("./assets/munch.png");
}

.operaen_img {
    background-image: url("./assets/operaen.png");
}

.torshov_img {
    background-image: url("./assets/torshov.png");
}

.nav_img {
    background-image: url("./assets/nav_mobil.jpg");
}

.church_sunset_img {
    background-image: url("./assets/church_unsplash.jpg");
}

.hover_text {
    color: #6a6760;
    text-align: center;
}

.some_links {
    display: block;
    width: 100%;
    height: 32px;
    border: 1px dotted red;
    overflow: scroll;
}

.instagram_logo {
    background-image: url("./assets/instagram.svg");
    height: 20px;
    width: 20px;
    float: right;
    margin-left: 15px;
    filter: invert(1);
}

.linkedin_logo {
    background-image: url("./assets/linked.svg");
    height: 20px;
    width: 20px;
    float: right;
    margin-left: 10px;
    filter: invert(1);
}

.facebook_logo {
    background-image: url("./assets/facebook.svg");
    height: 20px;
    width: 20px;
    float: right;
    filter: invert(1);
}

.copyright {
    text-align: center;
    margin: 2rem 0 2rem 0;
    color: #504f4f;
}

.dark_green_gradient {
    background: linear-gradient(111.357deg, #0081A8 0%, #01AFBA 100%);
}

.light_green_gradient {
    background: linear-gradient(111.357deg, #38B8B7 0%, #FDD9B7 100%);
}

.orange_gradient {
    background: linear-gradient(111.357deg, #FDD9B7 0%, #F07168 100%);
}

/* version 2 gradients */
.green_orange_gradient {
    background: linear-gradient(125.494deg, #5B8C6F 0%, #a57f56 75%);
}

.orange_green_gradient {
    background: linear-gradient(125.494deg, #a57f56 0%, #5B8C6F 75%);
}

.brown_gradient {
    /* background: linear-gradient(111.357deg, #575F50 0%, #B1A994 100%); */
    background: linear-gradient(180deg, #69625A 0%, #988b7d 100%);
}

.dark_gradient {
    background: linear-gradient(180deg, #313435 0%, #63574E 100%);
}

.light_box {
    background-color: #EFEBE2;
    color: #6a6760;
}

/* Hover */
.hover_textbox {
    background-color: #101010;
    color: #e0e0e0;
    height: 100%;
    text-align: center;
    opacity: 0;
    border-radius: 1rem;
    transition: opacity 300ms;
}

.hover_textbox:hover {
    opacity: 0.5;
}

.textbox {
    padding-top: 25%;
    font-family: "Lato", Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
}



/* Animert gradient */
@property --a{
    syntax: '<angle>';
    inherits: false;
    initial-value: 90deg;
  }
  @property --l{
    syntax: '<percentage>';
    inherits: false;
    initial-value: 10%;
  }
  @property --c{
    syntax: '<color>';
    inherits: false;
    initial-value: #69625A;
  }
  
  .box {
    /*  needed for firefox to have a valid output */
    --a:20deg;
    --l:10%;
    --c:#69625A;
    /**/
    /* cursor:pointer;
    width:500px;
    height:400px;
    margin:15px; */
    /* display:inline-block; */
    transition:--a 100.5s 56.1s,--l 100.5s,--c 100.2s;
    background:linear-gradient(var(--a), var(--c) var(--l),#988b7d,var(--c) calc(100% - var(--l)));
    animation:a 1s linear infinite alternate;
  }
  
  @keyframes a{
    from {
    --a:0deg;
    --l:10%;
    --c:#69625A;
    }
    50% {
      --c:#69625A;
    }
    to {
    --a:360deg;
    --l:40%;
    --c:#988b7d;
    }
  }

  /* en annen css animert bakgrunn */
.box2 {
    /* background: linear-gradient(180deg, #69625A 0%, #988b7d 100%); */
    background: linear-gradient(-45deg, #69625A 0%, #988b7d 100%);
	/* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
	background-size: 200% 200%;
	animation: gradient 15s ease infinite;
	/* height: 100vh; */
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


/* css hover effekt */

figure {
    display: block;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-left: 8px;
    padding-top: 16px;
}

/* Common style */
.grid_container figure {
	overflow: hidden;
    width: 100%;
    height: 100%;
    margin-top: -3px;
	text-align: center;
}

.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

.grid figure figcaption {
	padding: 1em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


/*---------------*/
/***** Julia *****/
/*---------------*/

figure.effect-julia img {
	max-width: none;
	height: 400px;
	-webkit-transition: opacity 1s, -webkit-transform 1s;
	transition: opacity 1s, transform 1s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

figure.effect-julia figcaption {
	text-align: left;
}

figure.effect-julia h2 {
	position: relative;
	padding: 0.5em 0;
}

figure.effect-julia p {
	display: inline-block;
	margin: 0 0 0.25em;
	padding: 0.4em 1em;
	background: rgba(255,255,255,0.9);
	color: #2f3238;
	text-transform: none;
	font-weight: 500;
	font-size: 75%;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(-360px,0,0);
	transform: translate3d(-360px,0,0);
}

figure.effect-julia p:first-child {
	-webkit-transition-delay: 0.15s;
	transition-delay: 0.15s;
}

figure.effect-julia p:nth-of-type(2) {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

figure.effect-julia p:nth-of-type(3) {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

figure.effect-julia:hover p:first-child {
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
}

figure.effect-julia:hover p:nth-of-type(2) {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

figure.effect-julia:hover p:nth-of-type(3) {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

figure.effect-julia:hover img {
	opacity: 0.4;
	-webkit-transform: scale3d(1.1,1.1,1);
	transform: scale3d(1.1,1.1,1);
}

figure.effect-julia:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

